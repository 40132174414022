<template>
  <div class="changeImei">
    <div class="wrap" :class="{showBtn}">
      <div class="item" v-for="(item,index) in dataList" :key="index">
        <div class="line">
          <p class="title">
            提交状态：
          </p>
          <p class="value">
            {{ tool.getChangeImeiType(item.status)}}
          </p>
        </div>
        <div class="line">
          <p class="title">
            卡号：
          </p>
          <p class="value">
            {{item.iccid}}
          </p>
        </div>
        <div class="line">
          <p class="title">
            IMEI：
          </p>
          <p class="value">
            {{item.imei}}
          </p>
        </div>
        <div class="line">
          <p class="title">
            提交时间：
          </p>
          <p class="value">
            {{item.submitTime}}
          </p>
        </div>
        <div class="line">
          <p class="title">
            审核内容：
          </p>
          <p class="value">
            {{item.remarks}}
          </p>
        </div>
      </div>
      <div class="tips" v-if="!dataList || dataList.length<=0">
        暂无机卡绑定历史工单~
      </div>
    </div>
    <van-button type="primary" size="large" block class="submit" v-if="showBtn" @click="showSubmitPop">提交工单</van-button>
    <change-imei ref="changeImei" :card='cardInfo.iccid' @refreshData='getData'></change-imei>
  </div>
</template>

<script>
import {
  toRefs,
  reactive,
  onMounted,
  getCurrentInstance,
  nextTick
} from "vue";
import { CardSeparationInfo } from "_API_/api.services";
export default {
  setup() {
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    const { $tool: tool,$store:store} = ctx;
    
    const state = reactive({
      cardInfo: store.state.user.userInfo,//卡号信息
      showBtn:false,
      dataList:[]
    });

    const methods = {
      getData(){
        CardSeparationInfo(state.cardInfo.iccid).then(({data})=>{
          console.log(data)
          if(data.code!=0){
            return tool.toast({
              msg:data.msg
            })
          }
          state.showBtn = data.isFlag
          state.dataList = data.data
        })
      },
      showSubmitPop(){
        nextTick(()=>{
          ctx.$refs.changeImei.isShow()
        })
      }
    };
    onMounted(() => {
      // 换卡数据
      methods.getData();
    });
    return { ...toRefs(state), ...methods,tool };
  }
};
</script>

<style lang="scss" scoped>
.changeImei {
  height: 100vh;
  position: relative;
  .wrap{
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    .item{
      box-sizing: border-box;
      width: 94%;
      padding: 3%;
      margin: 10px auto;
      background: #fff;
      border-radius: 10px;
      .line{
        display: flex;
        align-items: flex-start;
        p{
          margin: 3px 0;
          font-size: 26px;
        }
        p.title{
          width: 22%;
          color: #333;
        }
        p.value{
          width: 78%;
          color: #666;
          word-break: break-all;
        }
      }
    }
    .tips{
      width: 94%;
      padding: 20px 0;
      margin: 30px auto;
      color: #999;
      text-align: center;
      background: #fff;
      border-radius: 10px;
      font-size: 24px;
    }
  }
  .wrap.showBtn{
    padding-bottom: 100px;
  }
  .submit{
    position: fixed;
    bottom: 0;
    left: 0;
    height: 80px;
    line-height: 80px;
  }
}
</style>
